import { getRandomItem, getRandomItemWeighted, seedRandomItem } from '../lib/randomItem';
import challengeConfig from '../config/challengeConfig';
import { ethers } from 'ethers';
import { abis, addresses } from '@my-app/contracts';

function getVersionForBlock(blockNumber) {
  const versionInfo = challengeConfig.versions.find(v => blockNumber >= v.start && blockNumber <= v.stop);
  return versionInfo ? versionInfo.version : null;
}

async function getChallengeStats(provider, blockNumber, userAddress, fetchUserDetails) {
  const multicall = new ethers.Contract(addresses.multicall3, abis.Multicall3, provider);
  const beachheadInterface = new ethers.utils.Interface(abis.Beachhead);
  const beachheadJudgeInterface = new ethers.utils.Interface(abis.BeachheadJudge);
  const beachheadTokenInterface = new ethers.utils.Interface(abis.BeachheadToken);

  const initialCalls = [
    {
      name: 'getUserList',
      target: addresses.beachhead,
      allowFailure: true,
      callData: beachheadInterface.encodeFunctionData('getUserList', [blockNumber])
    },
    {
      name: 'getPassList',
      target: addresses.beachhead,
      allowFailure: true,
      callData: beachheadInterface.encodeFunctionData('getPassList', [blockNumber])
    },
    {
      name: 'getFailList',
      target: addresses.beachhead,
      allowFailure: true,
      callData: beachheadInterface.encodeFunctionData('getFailList', [blockNumber])
    },
    {
      name: 'getChallenge',
      target: addresses.beachhead,
      allowFailure: true,
      callData: beachheadInterface.encodeFunctionData('getChallenge', [blockNumber])
    },
    {
      name: 'duration',
      target: addresses.beachhead,
      allowFailure: true,
      callData: beachheadInterface.encodeFunctionData('duration')
    },
    {
      name: 'getUserProof',
      target: addresses.beachhead,
      allowFailure: true,
      callData: beachheadInterface.encodeFunctionData('getUserProof', [blockNumber, userAddress])
    },
    {
      name: 'getUserChallenge',
      target: addresses.beachhead,
      allowFailure: true,
      callData: beachheadInterface.encodeFunctionData('getUserChallenge', [blockNumber, userAddress])
    },
    {
      name: 'getVotes',
      target: addresses.beachheadJudge,
      allowFailure: true,
      callData: beachheadJudgeInterface.encodeFunctionData('getVotes', [blockNumber, userAddress])
    },
    {
      name: 'balances',
      target: addresses.beachheadJudge,
      allowFailure: true,
      callData: beachheadJudgeInterface.encodeFunctionData('balances', [userAddress])
    },
    {
      name: 'balanceOf',
      target: addresses.beachheadToken,
      allowFailure: true,
      callData: beachheadTokenInterface.encodeFunctionData('balanceOf', [userAddress])
    }
  ];

  const initialResults = await multicall.callStatic.aggregate3(initialCalls);

  const initialDecodedResults = initialResults.map(({ success, returnData }, i) => {
    if (!success) throw new Error(`Call failed for ${initialCalls[i].name}`);

    if (initialCalls[i].target === addresses.beachhead) {
      return beachheadInterface.decodeFunctionResult(initialCalls[i].name, returnData);
    } else if (initialCalls[i].target === addresses.beachheadJudge) {
      return beachheadJudgeInterface.decodeFunctionResult(initialCalls[i].name, returnData);
    } else if (initialCalls[i].target === addresses.beachheadToken) {
      return beachheadTokenInterface.decodeFunctionResult(initialCalls[i].name, returnData);
    } else {
      throw new Error(`Unknown target for call ${initialCalls[i].name}`);
    }
  });

  const userList = initialDecodedResults[0][0];
  const passList = initialDecodedResults[1][0];
  const failList = initialDecodedResults[2][0];
  const expireBlock = initialDecodedResults[3][0].toNumber();
  const total = initialDecodedResults[3][1];
  const pot = initialDecodedResults[3][2];
  const duration = initialDecodedResults[4][0].toNumber();
  const userReported = initialDecodedResults[5][0];
  const proofSuccess = initialDecodedResults[5][1];
  const proofURL = initialDecodedResults[5][2];
  const userTag = initialDecodedResults[6][0];
  const userURL = initialDecodedResults[6][1];
  const userBalance = initialDecodedResults[6][2];
  const userState = initialDecodedResults[6][3];
  const votesPass = initialDecodedResults[7][0];
  const votesFail = initialDecodedResults[7][1];
  const judgeBalance = initialDecodedResults[8][0];
  const userTokenBalance = initialDecodedResults[9][0];
  const judgmentExpireBlock = expireBlock + duration;

  const users = {};

  if (fetchUserDetails) {
    const userCalls = userList.flatMap((user) => [
      {
        name: 'getUserProof',
        target: addresses.beachhead,
        allowFailure: true,
        callData: beachheadInterface.encodeFunctionData('getUserProof', [blockNumber, user])
      },
      {
        name: 'getUserChallenge',
        target: addresses.beachhead,
        allowFailure: true,
        callData: beachheadInterface.encodeFunctionData('getUserChallenge', [blockNumber, user])
      },
      {
        name: 'getVotes',
        target: addresses.beachheadJudge,
        allowFailure: true,
        callData: beachheadJudgeInterface.encodeFunctionData('getVotes', [blockNumber, user])
      },
      {
        name: 'balances',
        target: addresses.beachheadJudge,
        allowFailure: true,
        callData: beachheadJudgeInterface.encodeFunctionData('balances', [user])
      },
      {
        name: 'getJudgeVote',
        target: addresses.beachheadJudge,
        allowFailure: true,
        callData: beachheadJudgeInterface.encodeFunctionData('getJudgeVote', [userAddress, blockNumber, user])
      },
      {
        name: 'balanceOf',
        target: addresses.beachheadToken,
        allowFailure: true,
        callData: beachheadTokenInterface.encodeFunctionData('balanceOf', [user])
      }
    ]);

    const userResults = await multicall.callStatic.aggregate3(userCalls);

    const userDecodedResults = userResults.map(({ success, returnData }, i) => {
      if (!success) throw new Error(`Call failed for ${userCalls[i].name}`);

      if (userCalls[i].target === addresses.beachhead) {
        return beachheadInterface.decodeFunctionResult(userCalls[i].name, returnData);
      } else if (userCalls[i].target === addresses.beachheadJudge) {
        return beachheadJudgeInterface.decodeFunctionResult(userCalls[i].name, returnData);
      } else if (userCalls[i].target === addresses.beachheadToken) {
        return beachheadTokenInterface.decodeFunctionResult(userCalls[i].name, returnData);
      } else {
        throw new Error(`Unknown target for call ${userCalls[i].name}`);
      }
    });

    for (let i = 0; i < userList.length; i++) {
      users[userList[i]] = {
        proof: {
          userReported: userDecodedResults[i * 6][0],
          proofSuccess: userDecodedResults[i * 6][1],
          proofURL: userDecodedResults[i * 6][2]
        },
        challenge: {
          tag: userDecodedResults[i * 6 + 1][0],
          url: userDecodedResults[i * 6 + 1][1],
          balance: userDecodedResults[i * 6 + 1][2],
          state: userDecodedResults[i * 6 + 1][3]
        },
        votes: {
          pass: userDecodedResults[i * 6 + 2][0],
          fail: userDecodedResults[i * 6 + 2][1]
        },
        judge: {
          balance: userDecodedResults[i * 6 + 3][0],
          voteSuccess: userDecodedResults[i * 6 + 4][0],
          voteWeight: userDecodedResults[i * 6 + 4][1]
        },
        tokenBalance: userDecodedResults[i * 6 + 5][0]
      };
    }
  }

  return {
    userList,
    passList,
    failList,
    participants: userList.length,
    passCount: passList.length,
    failCount: failList.length,
    total,
    pot,
    duration,
    expireBlock,
    judgmentExpireBlock,
    userReported,
    proofSuccess,
    proofURL,
    userTag,
    userURL,
    userBalance,
    userState,
    votesPass,
    votesFail,
    judgeBalance,
    userTokenBalance,
    users
  };
}

export async function rollChallenge(provider, blockNumber, userAddress, fetchUserDetails = false) {
  const block = await provider.getBlock(blockNumber);

  const version = getVersionForBlock(blockNumber);
  if (!version) {
    throw new Error(`No version found for block number ${blockNumber}`);
  }

  const currentBlock = await provider.getBlockNumber();

  const stats = await getChallengeStats(provider, blockNumber, userAddress, fetchUserDetails);

  let phase;
  if (currentBlock > stats.judgmentExpireBlock) {
    phase = 'Settlement';
  } else if (currentBlock > stats.expireBlock && currentBlock <= stats.judgmentExpireBlock) {
    phase = 'Judgement';
  } else if (currentBlock <= stats.expireBlock && currentBlock >= blockNumber) {
    phase = 'Active';
  } else {
    phase = 'Upcoming';
  }

  const missionData = {
    id: blockNumber,
    timestamp: 0,
    version: version,
    currentBlock: currentBlock,
    eta: stats.duration,
    phase: phase,
    stats: {
      ...stats
    }
  };

  if (block === null) {
    return missionData;
  } else {
    missionData.timestamp = block.timestamp;
  }

  seedRandomItem(block.hash);
  const challengeConfigVersion = challengeConfig[version];

  if (version === "1.0.0") {
    const selectedEnemy = getRandomItem(challengeConfigVersion.enemy);
    const selectedDifficulty = getRandomItem(challengeConfigVersion.difficulty);
    const selectedDuration = getRandomItem(challengeConfigVersion.duration);
    const selectedTeamplay = getRandomItem(challengeConfigVersion.teamplay);

    let selectedStars = getRandomItem(challengeConfigVersion.stars);
    while (!isValidStars(version, selectedDifficulty, selectedStars)) {
      selectedStars = getRandomItem(challengeConfigVersion.stars);
    }

    const selectedExtract = getRandomItem(challengeConfigVersion.extract);
    const selectedPrimary = getRandomItem(challengeConfigVersion.primary);
    const selectedSecondary = getRandomItem(challengeConfigVersion.secondary);
    const selectedGrenade = getRandomItem(challengeConfigVersion.grenade);

    let selectedStratagem1 = getRandomItem(challengeConfigVersion.SupportWeaponStratagems);
    let selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1], selectedStratagem2, challengeConfigVersion)) {
      selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2], selectedStratagem3, challengeConfigVersion)) {
      selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2, selectedStratagem3], selectedStratagem4, challengeConfigVersion)) {
      selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    }

    return {
      ...missionData,
      mission: {
        enemy: selectedEnemy,
        difficulty: selectedDifficulty,
        duration: selectedDuration,
        teamplay: selectedTeamplay,
        stars: selectedStars,
        extract: selectedExtract,
      },
      loadout: {
        primary: selectedPrimary,
        secondary: selectedSecondary,
        grenade: selectedGrenade,
        stratagem1: selectedStratagem1,
        stratagem2: selectedStratagem2,
        stratagem3: selectedStratagem3,
        stratagem4: selectedStratagem4,
      },
      success: {
        stars: selectedStars,
        extract: selectedExtract,
      },
    };
  } else if (version === "1.1.0") {
    const selectedEnemy = getRandomItem(challengeConfigVersion.enemy);
    const selectedDifficulty = getRandomItem(challengeConfigVersion.difficulty);
    const selectedDuration = getRandomItem(challengeConfigVersion.duration);
    const selectedTeamplay = getRandomItem(challengeConfigVersion.teamplay);

    let selectedStars = getRandomItem(challengeConfigVersion.stars);
    while (!isValidStars(version, selectedDifficulty, selectedStars)) {
      selectedStars = getRandomItem(challengeConfigVersion.stars);
    }

    const selectedPrimary = getRandomItem(challengeConfigVersion.primary);
    const selectedSecondary = getRandomItem(challengeConfigVersion.secondary);
    const selectedGrenade = getRandomItem(challengeConfigVersion.grenade);

    let selectedStratagem1 = getRandomItem(challengeConfigVersion.SupportWeaponStratagems);
    let selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1], selectedStratagem2, challengeConfigVersion)) {
      selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2], selectedStratagem3, challengeConfigVersion)) {
      selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2, selectedStratagem3], selectedStratagem4, challengeConfigVersion)) {
      selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    }

    return {
      ...missionData,
      mission: {
        enemy: selectedEnemy,
        difficulty: selectedDifficulty,
        duration: selectedDuration,
        teamplay: selectedTeamplay,
        stars: selectedStars,
      },
      loadout: {
        primary: selectedPrimary,
        secondary: selectedSecondary,
        grenade: selectedGrenade,
        stratagem1: selectedStratagem1,
        stratagem2: selectedStratagem2,
        stratagem3: selectedStratagem3,
        stratagem4: selectedStratagem4,
      },
      success: {
        stars: selectedStars,
      },
    };
  } else if (version === "1.1.1" || version === "1.1.2") {
    const selectedEnemy = getRandomItem(challengeConfigVersion.enemy);
    const selectedDifficulty = getRandomItem(challengeConfigVersion.difficulty);
    const selectedDuration = getRandomItem(challengeConfigVersion.duration);
    const selectedTeamplay = getRandomItemWeighted(challengeConfigVersion.teamplay, challengeConfigVersion.teamplayWeights);

    let selectedStars = getRandomItem(challengeConfigVersion.stars);
    while (!isValidStars(version, selectedDifficulty, selectedStars)) {
      selectedStars = getRandomItem(challengeConfigVersion.stars);
    }

    const selectedPrimary = getRandomItem(challengeConfigVersion.primary);
    const selectedSecondary = getRandomItem(challengeConfigVersion.secondary);
    const selectedGrenade = getRandomItem(challengeConfigVersion.grenade);

    let selectedStratagem1 = getRandomItem(challengeConfigVersion.SupportWeaponStratagems);
    let selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1], selectedStratagem2, challengeConfigVersion, selectedEnemy)) {
      selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2], selectedStratagem3, challengeConfigVersion, selectedEnemy)) {
      selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2, selectedStratagem3], selectedStratagem4, challengeConfigVersion, selectedEnemy)) {
      selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    }

    return {
      ...missionData,
      mission: {
        enemy: selectedEnemy,
        difficulty: selectedDifficulty,
        duration: selectedDuration,
        teamplay: selectedTeamplay,
        stars: selectedStars,
      },
      loadout: {
        primary: selectedPrimary,
        secondary: selectedSecondary,
        grenade: selectedGrenade,
        stratagem1: selectedStratagem1,
        stratagem2: selectedStratagem2,
        stratagem3: selectedStratagem3,
        stratagem4: selectedStratagem4,
      },
      success: {
        stars: selectedStars,
      },
    };
  } else if (version === "1.2.0") {
    const selectedEnemy = getRandomItem(challengeConfigVersion.enemy);
    const selectedTeamplay = getRandomItemWeighted(challengeConfigVersion.teamplay, challengeConfigVersion.teamplayWeights);

    let selectedDifficulty = getRandomItem(challengeConfigVersion.difficulty);
    while (!isValidDifficulty(version, selectedTeamplay, selectedDifficulty)) {
      selectedDifficulty = getRandomItem(challengeConfigVersion.difficulty);
    }

    const selectedDuration = getRandomItem(challengeConfigVersion.duration);

    let selectedStars = getRandomItem(challengeConfigVersion.stars);
    while (!isValidStars(version, selectedDifficulty, selectedStars)) {
      selectedStars = getRandomItem(challengeConfigVersion.stars);
    }

    const selectedPrimary = getRandomItem(challengeConfigVersion.primary);
    const selectedSecondary = getRandomItem(challengeConfigVersion.secondary);
    const selectedGrenade = getRandomItem(challengeConfigVersion.grenade);

    let selectedStratagem1 = getRandomItem(challengeConfigVersion.SupportWeaponStratagems);
    let selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1], selectedStratagem2, challengeConfigVersion, selectedEnemy)) {
      selectedStratagem2 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2], selectedStratagem3, challengeConfigVersion, selectedEnemy)) {
      selectedStratagem3 = getRandomItem(challengeConfigVersion.stratagem);
    }

    let selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    while (!isValidStratagem(version, [selectedStratagem1, selectedStratagem2, selectedStratagem3], selectedStratagem4, challengeConfigVersion, selectedEnemy)) {
      selectedStratagem4 = getRandomItem(challengeConfigVersion.stratagem);
    }

    return {
      ...missionData,
      mission: {
        enemy: selectedEnemy,
        difficulty: selectedDifficulty,
        duration: selectedDuration,
        teamplay: selectedTeamplay,
        stars: selectedStars,
      },
      loadout: {
        primary: selectedPrimary,
        secondary: selectedSecondary,
        grenade: selectedGrenade,
        stratagem1: selectedStratagem1,
        stratagem2: selectedStratagem2,
        stratagem3: selectedStratagem3,
        stratagem4: selectedStratagem4,
      },
      success: {
        stars: selectedStars,
      },
    };
  }
}

function isValidStratagem(version, selected, strat, config, selectedEnemy) {
  if (version === "1.0.0") {
    for (let i = 0; i < selected.length; i++) {
      if ((config.BackpackStratagems.includes(selected[i]) && config.BackpackStratagems.includes(strat)) ||
          (config.SupportWeaponStratagems.includes(selected[i]) && config.SupportWeaponStratagems.includes(strat)) ||
          selected[i] === strat) {
        return false;
      }
    }
  } else if (version === "1.1.0") {
    for (let i = 0; i < selected.length; i++) {
      if ((config.BackpackStratagems.includes(selected[i]) && config.BackpackStratagems.includes(strat)) ||
          (config.SupportWeaponStratagems.includes(selected[i]) && config.SupportWeaponStratagems.includes(strat)) ||
          (config.MechStratagems && config.MechStratagems.includes(selected[i]) && config.MechStratagems.includes(strat)) ||
          selected[i] === strat) {
        return false;
      }
    }
  } else if (version === "1.1.1") {
    for (let i = 0; i < selected.length; i++) {
      if ((config.BackpackStratagems.includes(selected[i]) && config.BackpackStratagems.includes(strat)) ||
          (config.SupportWeaponStratagems.includes(selected[i]) && config.SupportWeaponStratagems.includes(strat)) ||
          (config.MechStratagems && config.MechStratagems.includes(selected[i]) && config.MechStratagems.includes(strat)) ||
          selected[i] === strat) {
        return false;
      }
    }

    if (selectedEnemy === "Terminid" && strat === "SH-20 Balistic Shield Backpack") {
      return false;
    }
  } else if (version === "1.1.2" || version === "1.2.0") {
    for (let i = 0; i < selected.length; i++) {
      if ((config.BackpackStratagems.includes(selected[i]) && config.BackpackStratagems.includes(strat)) ||
          (config.SupportWeaponStratagems.includes(selected[i]) && config.SupportWeaponStratagems.includes(strat)) ||
          (config.MechStratagems && config.MechStratagems.includes(selected[i]) && config.MechStratagems.includes(strat)) ||
          selected[i] === strat) {
        return false;
      }
    }

    if ((selectedEnemy === "Terminid" && strat === "SH-20 Balistic Shield Backpack") ||
        (selectedEnemy === "Terminid" && strat === "FX-12 Shield Generator Relay")) {
      return false;
    }
  }

  return true;
}

function isValidStars(version, difficulty, stars) {
  if (version === "1.0.0") {
    return true;
  } else if (version === "1.1.0") {
    if ((difficulty === "4 - Challenging or greater" && stars === "4 stars or more") ||
        (difficulty === "4 - Challenging or greater" && stars === "5 stars") ||
        (difficulty === "5 - Hard or greater"        && stars === "5 stars") ||
        (difficulty === "6 - Extreme or greater"     && stars === "5 stars")) {
      return false;
    }
  } else if (version === "1.1.1" || version === "1.1.2") {
    if ((difficulty === "4 - Challenging or greater" && stars === "4 stars or more") ||
        (difficulty === "4 - Challenging or greater" && stars === "5 stars") ||
        (difficulty === "5 - Hard or greater"        && stars === "5 stars") ||
        (difficulty === "6 - Extreme or greater"     && stars === "5 stars")) {
      return false;
    }
  } else {
    if ((difficulty === "1 - Trivial or greater"      && stars === "3 stars or more") ||
        (difficulty === "1 - Trivial or greater"      && stars === "4 stars or more") ||
        (difficulty === "1 - Trivial or greater"      && stars === "5 stars") ||
        (difficulty === "2 - Easy or greater"         && stars === "3 stars or more") ||
        (difficulty === "2 - Easy or greater"         && stars === "4 stars or more") ||
        (difficulty === "2 - Easy or greater"         && stars === "5 stars") ||
        (difficulty === "3 - Medium or greater"       && stars === "4 stars or more") ||
        (difficulty === "3 - Medium or greater"       && stars === "5 stars") ||
        (difficulty === "4 - Challenging or greater"  && stars === "4 stars or more") ||
        (difficulty === "4 - Challenging or greater"  && stars === "5 stars") ||
        (difficulty === "5 - Hard or greater"         && stars === "5 stars") ||
        (difficulty === "6 - Extreme or greater"      && stars === "5 stars")) {
      return false;
    }
  }

  return true;
}

function isValidDifficulty(version, teamplay, difficulty) {
  if (version === "1.2.0") {
    if ((teamplay === "Solo" && difficulty === "7 - Suicide Mission or greater") ||
        (teamplay === "Solo" && difficulty === "8 - Impossible or greater") ||
        (teamplay === "Solo" && difficulty === "9 - Helldive or greater") ||
        (teamplay === "Solo" && difficulty === "10 - Super Helldive")) {
      return false;
    }

    if ((teamplay === "Squad" && difficulty === "1 - Trivial or greater") ||
        (teamplay === "Squad" && difficulty === "2 - Easy or greater") ||
        (teamplay === "Squad" && difficulty === "3 - Medium or greater") ||
        (teamplay === "Squad" && difficulty === "4 - Challenging or greater")) {
      return false;
    }
  }

  return true;
}
