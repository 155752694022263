import React, { useEffect, useState, useMemo } from 'react';
import { ethers } from 'ethers';
import {
  PageContainer,
  AboutContainer,
  ColumnContainer,
  SectionHeader,
  LogoImage,
  TextBlock,
  Table,
  TableRow,
  TableCell,
  TableLink,
} from './index';
import logo from '../assets/logo512.png';
import config from '../config';

const AboutView = ({ provider, ensAddress }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [addresses, setAddresses] = useState({
    beachhead: '',
    beachheadJudge: '',
    beachheadToken: '',
    usdc: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
  });
  const [totalSupply, setTotalSupply] = useState(0);
  const [treasuryBalance, setTreasuryBalance] = useState(0);
  const [incentiveBalance, setIncentiveBalance] = useState(0);

  const readOnlyProvider = useMemo(() => new ethers.providers.JsonRpcProvider(config.readOnlyUrls[config.readOnlyChainId], {
    name: "Base Mainnet",
    chainId: config.readOnlyChainId,
    ensAddress: ensAddress
  }), [ensAddress]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const ensProvider = provider.network?.ensAddress ? provider : readOnlyProvider;
        const beachhead = await ensProvider.resolveName('beachhead.base');
        const beachheadJudge = await ensProvider.resolveName('judge.beachhead.base');
        const beachheadToken = await ensProvider.resolveName('token.beachhead.base');
        setAddresses({
          beachhead,
          beachheadJudge,
          beachheadToken
        });
        const contract = new ethers.Contract(beachheadToken, ["function totalSupply() public view returns (uint256)", "function balanceOf(address) public view returns (uint256)"], readOnlyProvider);
        const supply = await contract.totalSupply();
        setTotalSupply(parseFloat(ethers.utils.formatUnits(supply, 18)).toFixed(2));
        const treasury = await contract.balanceOf("0xEeF3026eF864C9398c008195E65d16D9cb42a512");
        setTreasuryBalance(parseFloat(ethers.utils.formatUnits(treasury, 18)).toFixed(2));
        const incentive = await contract.balanceOf("0x006a1A936957b5286a92a4E1886e01C1985611bc");
        setIncentiveBalance(parseFloat(ethers.utils.formatUnits(incentive, 18)).toFixed(2));
      } catch (error) {
        console.error('Error fetching addresses from BNS:', error);
      }
    };

    fetchAddresses();
  }, [provider, readOnlyProvider]);

  const uniswapUrl = `https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=${addresses.beachheadToken}&chain=base`;
  const uniswapUsdcUrl = `https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=${addresses.usdc}&chain=base`;

  const Content = (
    <ColumnContainer>
      <LogoImage src={logo} alt="Logo" />
      <SectionHeader>The Beachhead</SectionHeader>
      <TextBlock>
        The Beachhead is a decentralized esports platform built on Ethereum/Base that gives every game a hardcore mode by allowing players to accept challenges in their favorite games. The Beachhead uses a commitment contract scheme where the player puts funds up on a challenge and attempts to complete the challenge within the timeframe outlined. The challenges are shared allowing many players to join together on a single challenge. Those players that lose the challenge forfeit half their funds to the pot. Those players that succeed their challenges have their funds returned to them along with the risk-weighted share of the challenge pot.
      </TextBlock>
      <SectionHeader>Beachhead Addresses</SectionHeader>
      <Table>
        <thead>
          <TableRow>
            <TableCell>Contract</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            <TableCell>Beachhead</TableCell>
            <TableCell><TableLink target="_blank" href={`https://basescan.org/address/${addresses.beachhead}`}>{addresses.beachhead || 'Loading...'}</TableLink></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>BeachheadJudge</TableCell>
            <TableCell><TableLink target="_blank" href={`https://basescan.org/address/${addresses.beachheadJudge}`}>{addresses.beachheadJudge || 'Loading...'}</TableLink></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>BeachheadToken</TableCell>
            <TableCell><TableLink target="_blank" href={`https://basescan.org/address/${addresses.beachheadToken}`}>{addresses.beachheadToken || 'Loading...'}</TableLink></TableCell>
          </TableRow>
        </tbody>
      </Table>
      <SectionHeader>Challenges</SectionHeader>
      <TextBlock>
        Challenges use a deterministic algorithm based on the `block.hash` to roll. This means that when a player joins an existing challenge, the rules for the challenge are the same for all participants of that challenge. This also means that players can choose to commit to a challenge in the future, where the rules of the challenge are unknown, but when the challenge is finally rolled, all players will be committed to the same challenge.
      </TextBlock>
      <SectionHeader>Active Phase</SectionHeader>
      <TextBlock>
        <strong>Accepting a Challenge</strong>
        <br />
        To accept a challenge the player must have some ETH on the Base chain and any amount of USDC over $1 to stake in The Beachhead. Again, this challenge may be an existing challenge that other players are on, it may be a new challenge the player thinks they can accomplish, or it may be a future challenge where the rules of the challenge are yet to be determined. The USDC is sent to the Beachhead contract, along with the player's gamer tag, and an optional streaming URL for others to watch. Once the player knows the rules of the challenge they can begin attempting it.
        <br /><br />
        For a limited time, upon accepting the challenge, the player will also be sent 1 Beachhead (BCHD) token to allow them to vote on other players of any other challenge. This incentive may also help compensate the user for ETH gas costs on the Base chain if they choose to sell the BCHD token. More on voting later.
      </TextBlock>
      <TextBlock>
        <strong>Completing a Challenge</strong>
        <br />
        Challenges are active for a limited time, usually ~24 hours, where the player can complete the challenge. The player is welcome to attempt to pass the challenge right up to the end of the active window.
        <br /><br />
        If the player passes the challenge, then they must submit a proof on-chain that indicates they passed the challenge. Their proof should be a URL to a youtube, twitch, or other streaming service video. This video proof will be reviewed by judges later in the judgement phase.
        <br /><br />
        If the player cannot complete the challenge before it's active phase ends, they are STRONGLY incentivized to self-report that they failed. If players self-report that they failed, they will get half their USDC back. If, however, they fail to report, or report they passed with a proof that is judged to be a failure, then they lose all their USDC.
        <br /><br />
        When a player fails, the funds they forfeit are split between judges, and a pot that will be shared amongst all the players that are judged to have passed a challenge.
      </TextBlock>
      <SectionHeader>Judgement Phase</SectionHeader>
      <TextBlock>
        When a challenge has made it to the judgement phase, players can no longer join the challenge or submit proofs if they passed or failed the challenge. It's time for the judges to determine if the participants of a challenge passed or failed.
        <br /><br />
        Judges are anyone with a Beachhead token (BCHD). This token can be easily purchased from the market. Judges must add this token to the BeachheadJudge contract in order to vote. Once BeachheadJudge is funded, the judge may use that vote weight to vote on the results of any player participating in a challenge. All their vote weight will be put on either pass or fail. During the challenge's judgement phase a judge may be convinced by the player, other judges, or the data to change their vote, this open discussion and any resulting voting changes are allowed. See the tokenomics section below to understand how the long-term incentives should push judges to grow The Beachhead, and judge players honestly.
      </TextBlock>
      <SectionHeader>Settlement Phase</SectionHeader>
      <TextBlock>
        Once the judgement period has elapsed, anyone may settle the challenge. This is a call to the Beachhead that will move every player to either the PASS or FAIL state. Once this call is made, all players that have been judged to have passed the challenge are allowed to withdraw their funds, plus their share of the pot.
      </TextBlock>
      <SectionHeader>Tokenomics of the Beachhead token (BCHD)</SectionHeader>
      <TextBlock>
        Judges are indirectly remunerated for judging as half the funds lost in challenges are delivered to a token burner that purchases BCHD tokens off the market and burns them out of existence. This will make the BCDH token supply shrink, which in turn should apply upward pricing pressure to BCHD tokens over time. This buy-and-burn should align Judges with both The Beachhead and the long-term health of its players.
        <br /><br />
        Even in the case where Judges could see immediate financial gain from incorrectly failing a player, that gain would be short-term and erode player trust. Players would ultimately choose not to participate in challenges, which would in-turn cause BCHD tokens to lose value thus harming Judges.
        <br /><br />
        If Judges see a bad actor that doesn't understand this long-term incentive, they should be that much more incentivized to vote correctly on the outcome of challenges. Remember, if bad judges get their way, it harms the value of all judges.
      </TextBlock>
      <SectionHeader>Initial Distribution of BCHD</SectionHeader>
      <TextBlock>
        The Beachhead Token (BCHD) is a fixed supply token and no more can be minted. The initial supply was 1,000,000 tokens. Due to the burn mechanics mentioned above, the current supply is now {totalSupply}.
        <br /><br />
        There are Permanent Market Makers (PMMs) that immutably custody 10% of the initial supply. As BCHD price increases, anyone can call buy() on these PMMs and they will sell some BCHD for ETH and then pair the two and add it as liquidity to uniswap. This should ensure that over time BCHD enjoys deep liquidity on the uniswap markets. Additionally, while that capital is idle, it's also available for flash lending. The initial distribution of BCHD tokens is as follows:
      </TextBlock>
      <Table>
        <thead>
          <TableRow>
            <TableCell>Price (BCHD/ETH)</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Initial Funding</TableCell>
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            <TableCell>15000</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0x55327Edb66baC95CF47F0aeF4D10d19232e14c55">0x55327Edb66baC95CF47F0aeF4D10d19232e14c55</TableLink></TableCell>
            <TableCell>0</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>10000</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0xfccD8868e7dD9A060AA12c3c3e4287Ce0D9b41c6">0xfccD8868e7dD9A060AA12c3c3e4287Ce0D9b41c6</TableLink></TableCell>
            <TableCell>2000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>5000</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0xAf0f9A7413B919e8F92a2ec53a5F37ecb870CbA5">0xAf0f9A7413B919e8F92a2ec53a5F37ecb870CbA5</TableLink></TableCell>
            <TableCell>3000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>2500</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0xc40e96A0E3a7194f4c70d3c6209Ba29212836B6D">0xc40e96A0E3a7194f4c70d3c6209Ba29212836B6D</TableLink></TableCell>
            <TableCell>5000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>1000</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0x32CF17b29516006f1B77853DFCfb21e4263f6850">0x32CF17b29516006f1B77853DFCfb21e4263f6850</TableLink></TableCell>
            <TableCell>10000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>500</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0xA1434AEf81564a8b56B3Df5B153cEB48da5DD829">0xA1434AEf81564a8b56B3Df5B153cEB48da5DD829</TableLink></TableCell>
            <TableCell>12000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>250</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0xf783b3426a6CB4E722Dc15a4aC0713F9fBEB7743">0xf783b3426a6CB4E722Dc15a4aC0713F9fBEB7743</TableLink></TableCell>
            <TableCell>12000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>100</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0x101843323D6b289c19BA618496211280b4edBa78">0x101843323D6b289c19BA618496211280b4edBa78</TableLink></TableCell>
            <TableCell>24000</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>50</TableCell>
            <TableCell><TableLink target="_blank" href="https://basescan.org/address/0x6cB7FbC25c37FaA64019B64b879d5DFE961301b9">0x6cB7FbC25c37FaA64019B64b879d5DFE961301b9</TableLink></TableCell>
            <TableCell>32000</TableCell>
          </TableRow>
        </tbody>
      </Table>
      <TextBlock>
        This totals to 100,000 BCHD tokens. The remaining 900,000 BCHD tokens were distributed to the team, treasury, incentives, and initial liquidity. Team tokens are streamed when distributed.
        <br /><br />
        The treasury balance is {treasuryBalance} BCHD.
        <br /><br />
        The BCHD incentive for players holds {incentiveBalance} BCHD.
        <br /><br />
        The initial liquidity  of 70,000 BCHD was provided by the treasury and paird with 5 ETH in uniswap v2.
        <br /><br />
        The remaining tokens are distributed through Sablier streams as follows:
      </TextBlock>
      <Table>
        <thead>
          <TableRow>
            <TableCell>Allocation</TableCell>
            <TableCell>Stream</TableCell>
            <TableCell>Duration</TableCell>
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            <TableCell>300,000</TableCell>
            <TableCell><TableLink target="_blank" href="https://app.sablier.com/stream/LL3-8453-19/">Founder</TableLink></TableCell>
            <TableCell>4 years</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>300,000</TableCell>
            <TableCell><TableLink target="_blank" href="https://app.sablier.com/stream/LL3-8453-18/">Founding Engineer</TableLink></TableCell>
            <TableCell>4 years</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>100,000</TableCell>
            <TableCell><TableLink target="_blank" href="https://app.sablier.com/stream/LL3-8453-20/">R&D for Automated Judging</TableLink></TableCell>
            <TableCell>4 years</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>20,000</TableCell>
            <TableCell><TableLink target="_blank" href="https://app.sablier.com/stream/LL3-8453-85/">PMM & SC Review Engineer</TableLink></TableCell>
            <TableCell>4 years</TableCell>
          </TableRow>
        </tbody>
      </Table>
      <SectionHeader>Recources</SectionHeader>
      <TextBlock>
        The following are links to additional resources related to The Beachhead.
      </TextBlock>
      <Table>
        <thead>
          <TableRow>
            <TableCell>Resource</TableCell>
            <TableCell>Link</TableCell>
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            <TableCell>Community</TableCell>
            <TableCell><TableLink target="_blank" href="https://discord.gg/P7hD7g2rM9">discord</TableLink></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>YouTube</TableCell>
            <TableCell><TableLink target="_blank" href="https://www.youtube.com/@TheBeachhead">YouTube</TableLink></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Buy/Sell USDC</TableCell>
            <TableCell><TableLink target="_blank" href={uniswapUsdcUrl}>uniswap</TableLink></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Buy/Sell BCHD</TableCell>
            <TableCell><TableLink target="_blank" href={uniswapUrl}>uniswap</TableLink></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>BCHD Charts</TableCell>
            <TableCell><TableLink target="_blank" href="https://www.geckoterminal.com/base/pools/0x6497848f85a7109404e881f5dad9fbb63734fdcf">geckoterminal</TableLink></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Smart Wallet</TableCell>
            <TableCell><TableLink target="_blank" href="https://wallet.coinbase.com/">wallet.coinbase.com</TableLink></TableCell>
          </TableRow>
        </tbody>
      </Table>
    </ColumnContainer>
  );

  return (
    <PageContainer>
      {isMobile ? Content : <AboutContainer>{Content}</AboutContainer>}
    </PageContainer>
  );
};

export default AboutView;
